<template>
  <div class="single-goods">
    <!-- 路由出口 -->
    <router-view ref="child" @openPopup="openPopup"></router-view>
    <!-- 底部导航栏  -->
    <van-goods-action
      :safe-area-inset-bottom="true"
      class="dark_bg padding-bottom"
    >
      <!-- <van-goods-action-icon
              class="dark_bg dark_text"
              icon="chat-o"
              text="客服"
              :to="{
                path: '/customerChat',
                query: {
                  goodsHomeId: goods.id,
                },
              }"
            /> -->
      <!-- <van-goods-action-icon
                    class="dark_bg dark_text"
                    icon="chat-o"
                    text="客服"
                    @click="service"
            /> -->
      <van-goods-action-icon
        class="dark_bg dark_text"
        v-if="!goods.collect"
        icon="star-o"
        text="收藏"
        @click="tollgleCollectsell()"
      />
      <van-goods-action-icon
        v-else
        icon="star"
        text="已收藏"
        @click="tollgleCollectsellNo()"
        class="collect-active dark_bg dark_text"
      />
      <van-goods-action-icon
        icon="cart-o"
        text="购物车"
        to="/shoppingcar?goodsdetail=1"
        class="dark_bg dark_text"
      />
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        @click="openPopup(1)"
      />
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="openPopup"
      />
    </van-goods-action>
    <!-- 用户没有地址时的弹窗提示 -->
    <van-dialog v-model="showAddress" :show-confirm-button="false">
      <p class="addressTip">您还没有设置收货地址</p>
      <div class="handleBtn">
        <p style="color: #ce4434" @click="cancleHandle">取消</p>
        <p
          style="background-color: #ce4434; color: #fff"
          @click="confirmHandle"
        >
          去设置
        </p>
      </div>
    </van-dialog>
    <!-- 商品规格 -->
    <van-popup
      v-model="showBase"
      position="bottom"
      id="sku-popup"
      class="showBaseStyle"
    >
      <!-- 关闭键 -->
      <div class="del_pouop">
        <div class="iconfont icon-cuowu close-size" @click="closePopup"></div>
      </div>
      <!-- 商品信息 -->
      <van-card centered :thumb="choiceGoods.img">
        <span slot="title" class="goods_title"
          >￥{{ (choiceGoods.price / 100).toFixed(2) }}</span
        >
        <span slot="origin-price" v-if="choiceGoods.discountMoney"
          >￥{{ choiceGoods.money }}</span
        >
        <!-- <span slot="price" class="goods_price"
                  >{{ choiceGoods.score }}积分</span
                > -->
      </van-card>
      <!-- 规格 -->
      <dl class="sku">
        <dd>规格</dd>
        <dt>
          <p
            v-for="(sku, index) in sku"
            :key="sku.id"
            :class="{
              active: checkedIndex === index,
              disabled: sku.stock === 0,
            }"
            @click="checkSku(index, sku.id)"
          >
            {{ sku.title }}
          </p>
        </dt>
          <dd>选择服务门店</dd>
        <dt>
          <p v-if="noServicePoint" class="service-oint">无服务点</p>
          <p v-else @click="selectMerchant()" class="service-oint">
            {{ sku.servicePoint ?sku.servicePoint:'请选择服务点'}}
          </p>
        </dt>
      </dl>
      <!-- 购买数量 -->
      <section class="buy_num">
        <p>购买数量</p>
        <van-stepper v-model="count" />
      </section>
      <!-- 剩余件数+运费 -->
      <van-cell-group>
        <van-cell style="background: #fafafa">
            <!-- <van-col span="8" v-if="goods.sales === 0">运费：包邮</van-col>
            <van-col span="8" v-else>运费：￥{{ goods.sales }}</van-col> -->
            <van-col v-if="!choiceGoods.stock"
            >剩余0件(暂无库存)
            </van-col
            >
            <van-col v-else>剩余{{ choiceGoods.stock }}件数</van-col>
        </van-cell>
      </van-cell-group>

      <!-- 仓库 -->
      <!-- <van-cell
        @click="openWarehouse()"
        style="background: #fafafa"
        title="仓库"
        is-link
      >
      </van-cell>
      <van-popup
        v-model="warehouseShow"
        closeable
        close-icon="close"
        position="bottom"
        class="warehousePopupBox"
      >
        <div class="warehousePopup">
          <div class="warehousePopupTitle">仓库</div>
          <van-radio-group v-model="warehouseRadio" class="warehouseRadioStyle">
            <van-radio
              v-for="(item, index) in 11"
              :key="index"
              :name="index"
              class="warehouseRadioItem"
              >单选框 1</van-radio
            >
          </van-radio-group>
        </div>
      </van-popup> -->

      <!-- 底部按钮 -->
      <van-goods-action :safe-area-inset-bottom="true">
        <!-- text="加入购物车" -->
        <van-goods-action-button
          type="warning"
          text="确认"
          @click="addCart"
          v-if="isCar"
          class="confirm_addshop"
        />
        <van-goods-action-button
          v-else
          type="danger"
          text="立即购买"
          @click="goOrderSingle"
          class="buy_now"
        />
      </van-goods-action>
    </van-popup>
    <van-popup
      v-model="showMerchant"
      position="bottom"
      class="merchant-popup"
      :style="{ height: '90%' }"
    >
      <div class="back" @click="back()">
        <span class="iconfont icon-fanhui"></span>
        <span class="back-text">返回</span>
      </div>
        <div
          class="merchant-list"
          v-for="(item, index) in selectMerchantList"
          :key="index"
          @click="handleMerchant(item)"
        >
          <div class="merchant-name">{{ item.name }}</div>
          <div class="merchant-info">
              <div class="merchant-info-item">
              <span class="info-item-text">联系人: </span>
              <span class="info-item-vaule">{{ item.linkName }}</span>
            </div>
            <div class="merchant-info-item">
              <span class="info-item-text">电话：</span>
              <span class="info-item-vaule">{{ item.mobile }}</span>
            </div>
            <div class="merchant-info-item">
              <span class="info-item-text">地址：</span>
              <span class="info-item-vaule">{{ item.address }}</span>
            </div>
          </div>
        </div>
      
    </van-popup>
  </div>
</template>

<script>
import {
  Swipe,
  SwipeItem,
  Cell,
  CellGroup,
  Col,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Popup,
  Card,
  Stepper,
  Dialog,
  Button,
} from "vant";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      goods: {},
      sku: [], // 商品规格列表
      checkedIndex: -1, // 是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
      checked: {}, // 存放被选中的值
      count: 1,
      showBase: false, // 是否显示商品规格
      currentId: null,
      checkedId: null,
      collectStatus: 0,
      accessToken: "",
      isfixed: false,
      showAddress: false, // 是否显示添加地址提示,
      noAddressTip: "",
      // fatherMethod: this.openPopup,
      isCar: false, //是否是购物车
      addCollectflag: false,
      deleteCollectflag: false,
      warehouseShow: false, //: 仓库
      warehouseRadio: 1,
      selectMerchantList:[],//服务点数组
      showMerchant:false,
      isServicePoint:{},
      noServicePoint:false
    };
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [CellGroup.name]: CellGroup,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Sku.name]: Sku,
    [Popup.name]: Popup,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
  },
  activated() {
    this.showAddress = false;
    this.showBase = false;
    // 德合汇对接
    this.deHeConnect();
    // 公众号支付
    // this.getOpenid();
    this.accessToken = localStorage.getItem("token");
    // isUseCache为false时才重新刷新获取数据
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      this.count = 1;
      this.goods = {};
      this.getDetail();
    }
    // // 通过这个控制刷新
    this.$route.meta.isUseCache = false;
  },
  methods: {
    //服务点选择
   selectMerchant() {
      this.selectMerchantList = [];
       this.$api.shopcar.getServiceShop({supplierId:this.goods.supplierId}).then(res=>{
         console.log(res);
         this.selectMerchantList = res.data;
          this.showMerchant = true;
          if(!this.selectMerchantList){
            this.$toast({
              message: "不存在服务点",
              duration: 1000,
            });
            this.showMerchant = false;
            this.noServicePoint = true
          }
           this.loadMerchantData();
       })
    },
    loadMerchantData(){

    },
    back(){
      this.showMerchant = false;
    },
    handleMerchant(item){
      this.sku.servicePoint = item.name
      this.isServicePoint =item
      this.showMerchant = false;
    },

    // 打开仓库弹窗
    openWarehouse() {
      this.warehouseShow = true;
    },
    // 获取仓库列表
    getStash() {
      this.$api.stash
        .getListStashSku({
          userId: localStorage.getItem("customerId"),
          skuId: this.skuIdStash,
        })
        .then((res) => {
          if (res.errno === 200) {
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 获取openid
    getOpenid() {
      const openid = this.$route.query.openid;
      if (openid) {
        localStorage.setItem("openid", openid);
      }
    },
    deHeConnect() {
      const token = this.$route.query.token;
      const isDehe = this.$route.query.isDehe;
      // 如果查询参存在且不为空s
      if (isDehe && isDehe.length > 0) {
        localStorage.setItem("isDehe", isDehe);
      }
      if (token && token.length > 0) {
        // 如果查询参存在且不为空
        localStorage.setItem("token", token);
      }
    },
    goBack() {
      this.$router.back(0);
    },
    confirmHandle() {
      this.showAddress = false;
      this.$router.push({ path: "/editaddress" });
    },
    cancleHandle() {
      this.showAddress = false;
    },
    // 调出规格框
    openPopup(num) {
      if (num === 1) {
        this.isCar = true;
      } else {
        this.isCar = false;
      }
      // 如果未登录直接跳转到登录界面
      if (localStorage.getItem("token")) {
        this.showBase = true;
      } else {
        this.$router.push({
          path: "/login",
          query: { redirect: this.$route.path },
        });
      }
    },
    // 关闭规格窗
    closePopup() {
      this.showBase = false;
    },
    // 获取商品详情数据
    getDetail() {
      // 接收detail组件传过来商品的值
      this.$root.eventHub.$on("sendGoods", (goodsInfo) => {
        // console.log("接收detail组件传过来商品的值", goodsInfo);
        this.goods = goodsInfo;
        this.noServicePoint = false
        
        console.log(this.goods,'god');
        if(!this.goods.supplierName) this.noServicePoint = true

        //console.log(this.goods.collect);
        // this.collectStatus = goodsInfo.goodsHomeVO.collectStatus;
        this.sku = goodsInfo.skuDTOList;
        // this.skuIdStash = this.sku[0].id
        // this.getStash()
        this.checked = this.goods;
        // 初始化规格
        this.initSku();
        // 添加足迹
        // this.addFoots();
      });
    },
    initSku() {
      this.sku.some((value, index) => {
        // 筛选出规格中第一个有库存的商品
        if (value.stock > 0) {
          this.checkedIndex = index;
          this.checkedId = value.id;
          this.imageURL = value.img;
          this.checked = value;
          return true; // 满足条件第一个就跳出循环
        }
      });
    },
    // 选择规格
    checkSku(index, id) {
      // 如果被选中商品规格为,做提示
      if (this.sku[index].stock === 0) {
        return this.$toast({
          message: "暂无库存",
          duration: 1000,
        });
      }
      //  遍历规格商品
      this.sku.forEach((val, i) => {
        if (id === val.id && val.stock) {
          // 如果选中的商品并且有库存
          //console.log(this.checkedIndex);
          // //console.log(this.checkedId);
          this.checkedIndex = index;
          this.checkedId = val.id;
          this.imageURL = val.img; // 切换图片
          // return;
          return (this.checked = val); // 跳出循环
        }
      });
    },
    // 进入页面添加足迹
    addFoots() {
      this.$api.home
        .addFootPrintAddress({
          goodsHomeId: this.goods.id,
          accessToken: this.accessToken,
          customerId: localStorage.getItem("customerId"),
        })
        .then((res) => {});
    },
    // 加入购物车
    addCart() {
      if (this.count > this.choiceGoods.stock) {
        this.$toast("购买商品数量不能大于库存!");
        return;
      }
      // 判断用户是否选中了规格
      //console.log("this.checked判断用户是否选中了规格", this.checked);
      if (this.checkedIndex != -1) {
        let companyId = this.$route.query.companyId;
        if (this.$route.query.companyId == "undefined") companyId = "";
        this.$api.shopcar
          .goodsNumCarAddress({
            // goodsId: this.checkedId,
            // accessToken: this.accessToken,
            // stock: this.count,
            // companyId: companyId,
            userId: localStorage.getItem("customerId"),
            skuId: this.checkedId,
            num: this.count,
            storeId: localStorage.storeId,
            serviceId:this.isServicePoint.id
          })
          .then((res) => {
            if (res.errno === 200) {
              this.$toast.success({
                message: "添加成功",
                duration: 1000,
                onClose: () => {
                  this.showBase = false;
                },
              });
            } else {
              this.$toast(res.errmsg);
            }
          });
      } else {
        this.$toast("请选择商品规格");
      }
    },
    // 预下单
    goOrderSingle() {
      if (this.count > this.choiceGoods.stock) {
        this.$toast("购买商品数量不能大于库存!");
        return;
      }
      let companyId = this.$route.query.companyId;
      if (this.$route.query.companyId == "undefined") companyId = "";
      if (this.checkedIndex != -1) {
        // console.log("this.goods", this.goods);
        let skuItem = {
          skuId: this.checkedId,
          servicePoint: this.sku.servicePoint,
          serviceId: this.isServicePoint.id,
          spuId: this.goods.id,
          title: this.goods.title,
          price: this.goods.skuDTOList[this.checkedIndex].price,
          img: this.goods.img,
          num: this.count,
          sales: this.sales,
          takeWay: "buy",
          isFood: this.$route.query.isFood,
          color: this.goods.skuDTOList[this.checkedIndex].title,
          skuOriginalId: this.goods.skuDTOList[this.checkedIndex].skuOriginalId,
        };
        let skuGoodsList = [];
        skuGoodsList[0] = skuItem;
        // console.log(skuGoodsList);
        localStorage.setItem("skuGoodsList", JSON.stringify(skuGoodsList));
        localStorage.setItem("skuGoodsListIndex", this.checkedIndex);

        //console.log(
        //   "详情 取skuGoodsList",
        //   JSON.parse(localStorage.getItem("skuGoodsList"))
        // );
        // this.$api.globalData.skuGoodsList = skuGoodsList;
        this.$router.push({
          path: "/orderconfirm",
        });

        // let orderList = JSON.stringify([
        //   { goodsId: this.checkedId, stock: this.count, companyId: companyId },
        // ]);
        // this.$api.home
        //   .preOrderAddress({
        //     accessToken: this.accessToken,
        //     orderList,
        //   })
        //   .then((res) => {
        //     // 下单成功返回订单id
        //     if (res.code === 100) {
        //       this.$router.push(`/orderconfirm/${res.returnValue.orderId}`);
        //     } else if (res.code === 141) {
        //       this.noAddressTip = res.message;
        //       this.showAddress = true;
        //       this.showBase = false;
        //     } else {
        //       this.$toast(res.message);
        //     }
        //   });
      } else {
        this.$toast("请选择商品规格");
      }
    },
    // debounce(func, delay) {
    //     console.log('防抖', func);
    //     let timer = null;
    //     return function() {
    //         const context = this;
    //         const args = arguments;
    //         console.log('防抖2', func);
    //         clearTimeout(timer);
    //         timer = setTimeout(function() {
    //         func.apply(context, args);
    //         console.log('防抖', func);
    //         }, delay);
    //     }
    // },
    // 收藏+取消收藏
    tollgleCollectsell() {
      //console.log(this.accessToken);
      if (this.accessToken) {
        //console.log(this.collectStatus);
        // 如果为真
        this.$api.home
          .addCollect({
            userId: localStorage.customerId,
            // collectType: "3", // 收藏类型 1产品,2文章,3商品
            spuId: this.goods.id, // 收藏对象Id
            storeId: localStorage.storeId,
          })
          .then((res) => {
            this.$refs.child.getDetail();
          });
      } else {
        this.$toast('收藏需要登录，可以前往"我的"进行登录');
      }
    },
    service() {
      this.$router.push("/customerChat");
    },
    tollgleCollectsellNo() {
      if (this.accessToken) {
        this.$api.home
          .deleteCollect({
            userId: localStorage.customerId,
            // collectType: "3", // 收藏类型 1产品,2文章,3商品
            spuIds: this.goods.id, // 收藏对象Id
          })
          .then((res) => {
            this.$refs.child.getDetail();
          });
      } else {
        this.$toast('收藏需要登录，可以前往"我的"进行登录');
      }
    },
  },
  computed: {
    // 监听选中项的改变
    choiceGoods: {
      get: function () {
        return this.checked;
      },
      set: function (newValue) {
        return (this.checked = newValue);
      },
    },
    ...mapGetters(["customerId"]),
  },
  beforeRouteLeave(to, from, next) {
    this.$root.eventHub.$off("sendGoods");
    next();
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";

@red: #c83f3fb8;
.single-goods {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 44px;
  // 收藏选中颜色
  .collect-active {
    /deep/ .van-goods-action-icon__icon {
      // color: rgb(247, 152, 111);
      color: @collected_active_icon_color;
    }
  }

  .van-swipe {
    position: relative;

    .van-swipe-item {
      img {
        width: 100%;
        display: block;
      }
    }

    .custom-indicator {
      position: absolute;
      bottom: 0;
      left: 50%;
      text-align: center;
    }
  }

  .van-dialog {
    width: 60%;
    // background: url("../../../static/images/noaddress.png") no-repeat;
    background-size: 100% 100%;

    .addressTip {
      width: 100%;
      box-sizing: border-box;
      padding: 160px 10px 40px;
      line-height: 24px;
      text-align: center;
    }

    .handleBtn {
      .list(row);
      padding: 0 0.32rem /* 16/50 */ 0.32rem /* 16/50 */;
      justify-content: space-between;
      background-color: #fff;

      & > p {
        border: 1px solid #ce4434;
        font-size: 0.28rem /* 14/50 */;
        text-align: center;
        border-radius: 0.64rem /* 32/50 */;
        letter-spacing: 1px;
        width: 46%;
        padding: 0.12rem /* 6/50 */ 0;
      }
    }
  }

  .van-cell-group {
    .title {
      font-size: 0.26rem /* 13/50 */;
      color: rgb(107, 106, 106);
    }

    .price {
      color: @price_text_color;
    }
  }

  .promise {
    padding: 0.16rem /* 8/50 */ 0.24rem /* 12/50 */;
    .list(row);
    background-color: #2e2e2e;
    color: #fff;
    align-items: center;

    & > span {
      color: #faf579;
      font-size: 0.64rem /* 32/50 */;
      margin-right: 0.32rem /* 16/50 */;
    }

    & > ul {
      li {
        line-height: 0.38rem /* 19/50 */;

        &:last-child {
          font-size: 0.22rem /* 11/50 */;
          color: #ffffffb3;
        }
      }
    }
  }

  .showBaseStyle {
    border-top-left-radius: 0.16rem /* 8/50 */;
    border-top-right-radius: 0.16rem /* 8/50 */;
    background-color: #fafafa;
    padding-bottom: 2.28rem /* 64/50 */;

    .van-goods-action {
      bottom: 3px;
    }

    .del_pouop {
      padding: 0.24rem /* 12/50 */;
      text-align: right;

      span {
        font-size: 0.4rem /* 20/50 */;
      }

      .close-size {
        font-size: 0.4rem /* 20/50 */;
      }
    }

    .van-card {
      .goods_title {
        font-size: 0.4rem /* 20/50 */;
        color: @addshop_goods_title_color;
      }

      .goods_price {
        color: rgb(145, 144, 144);
      }
    }

    .sku {
      padding: 0.24rem /* 12/50 */ 0.24rem /* 12/50 */ 0;

      dd {
        color: #333;
        margin: 0 0 0.16rem /* 8/50 */;
        font-size: 0.28rem /* 14/50 */;
      }

      dt {
        .list(row);
        flex-wrap: wrap;

        p {
          color: #999;
          border: 1px solid #999;
          text-align: center;
          border-radius: 0.32rem /* 16/50 */;
          font-size: 0.24rem /* 12/50 */;
          padding: 0.06rem /* 3.2/50 */ 0.16rem /* 8/50 */;
          letter-spacing: 1px;
          margin: 0 0.12rem /* 6/50 */ 0.12rem /* 6/50 */ 0;

          &.active {
            color: @specification_text_color;
            border-color: @specification_border_color;
          }

          &.disabled {
            color: #dfdbdb;
            border-color: #edecec;
          }
        }
      }
    }

    .buy_num {
      padding: 0.24rem /* 12/50 */;
      .list(row);
      justify-content: space-between;
      align-items: center;
      font-size: 0.28rem /* 14/50 */;
      color: black;
    }
  }
}

.van-goods-action {
  z-index: 999;
}
.van-goods-action-button--first {
  width: 2.3rem;
  height: 0.8rem;
  // background: linear-gradient(270deg, #FFA705, #F8BD00);
  background: @van_goods_action_button_first;
  border-radius: 0.1rem 0px 0px 0.1rem;

  font-size: 0.3rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.3rem;
}
.van-goods-action-button--last {
  width: 2.3rem;
  height: 0.8rem;
  // background: linear-gradient(90deg, #FE4818, #F20404);
  background: @van_goods_action_button_last;
  border-radius: 0px 0.1rem 0.1rem 0px;
  font-size: 0.3rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.3rem;
}

// 适配IPoneX底部样式
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  #sku-popup {
    padding-bottom: 1.92rem /* 96/50 */;
  }
}
// .padding-bottom{
//         padding-bottom: 0.22rem /* 96/50 */;
// }
// 确认添加购物车
.confirm_addshop {
  background: @confirm_addshop_button_background;
}
// 立即购买
.buy_now {
  background: @buy_now_button_background;
}
// 仓库
// .warehousePopupBox {
//   font-size: 0.28rem /* 14/50 */;
//   border-top-left-radius: 0.16rem /* 8/50 */;
//   border-top-right-radius: 0.16rem /* 8/50 */;
//   background-color: #fafafa;
// }
// .warehousePopup {
//   .warehousePopupTitle {
//     font-size: 0.32rem /* 16/50 */;
//     text-align: center;
//     padding: 0.4rem /* 20/50 */;
//   }
//   .warehouseRadioStyle {
//     padding: 0 0.4rem /* 20/50 */;
//     height: 8rem /* 400/50 */;
//     overflow: scroll;
//     .warehouseRadioItem {
//       margin: 0.4rem /* 20/50 */ 0;
//     }
//   }
// }
.service-oint{
  width: 100%;
}
.back-text{
  font-size: .4rem;
}
.merchant-list{
  width: 90%;
  margin-left: 5%;
  margin-top: .4rem;
  // background-color: rgba(0, 0, 0, .1);
      box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    border-radius: .2rem;
    padding: .26rem;
    box-sizing: border-box;
    .merchant-name{
      font-size: .32rem;
      line-height: 1.5;
    }
}
.merchant-name{
  line-height: 1.5;
  font-size: .26rem;
}
.merchant-info{
  line-height: 1.5;
  font-size: .26rem;
}
</style>
